<template>
	<div class="page-content  white-bg">
		<NewsGuide  :typeId ='typeId'/>
	</div>
</template>

<script>

export default {
  name: "home",
  	data(){
		return{
			typeId: "10000056",
			
		}
	},
	methods:{
	}

};
</script>
<style lang="less" scoped>


</style>
